.xpo-AgGrid {
  .ag-root-wrapper-body.ag-layout-normal {
    border-top: 1px solid lightgray;
  }
}

app-welcome {
  mat-drawer-content.xpo-TabDrawer-contentWrapper.mat-drawer-content {
    overflow-y: hidden;
  }
}

.mat-select-form {
  span.mat-select-placeholder {
    font-size: 1rem;
    letter-spacing: normal;
    opacity: 0.5;
    color: #333333 !important;
  }
}

.mat-placeholder-required {
  color: #d50000;
}
